import React from "react"
import IdeallineApp from "./IdeallineApp"
import FirebaseProvider from "./Firebase"
import { BrowserRouter } from "react-router-dom"

const Idealline = (props) => (
    <BrowserRouter>
        <FirebaseProvider>
            <IdeallineApp {...props} />
        </FirebaseProvider>
    </BrowserRouter>
)
export default Idealline
