import React from "react"
import Idealline from "../../components/idealline"
import { ClientOnly } from "../../components/common"

const Embed = () => {
    return (
        <ClientOnly>
            <Idealline apikey={process.env.GATSBY_GMAPS_API_KEY} embedLayout />
        </ClientOnly>
    )
}

export default Embed
